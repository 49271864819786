<template>
	<div>
		<load v-if="loader" color="#2474b2" size="70" />
		<ValidationObserver ref="form" v-else>
			<form class="modal_login row" @submit.prevent="logCpf">
				<div class="col-md-12 my-2">
					<label class="label_default mb-0">
						Escolha a
						<strong>Unidade</strong>
					</label>
				</div>
				<ValidationProvider
					v-slot="{ errors, ariaMsg, classes }"
					rules="required"
					name="Unidade"
					tag="div"
					:bails="false"
					class="col-md-12 my-1"
				>
					<select class="input_default form-control" v-model="unidadeSelect" :class="classes">
						<option :value="{tenancyName:'master',id:0}">MASTER</option>
						<option :value="item" v-for="(item,index) in unidades" :key="index">{{item.tenancyName}}</option>
					</select>
					<span v-bind="ariaMsg" :class="classes">{{ errors[0] }}</span>
				</ValidationProvider>
				<div class="col-md-12 my-1">
					<button type="submit" class="btn_default px-2 py-2" :disabled="disabled" id="buttonSubmitLogin">
						<span
							class="spinner-border spinner-border-sm"
							role="status"
							aria-hidden="true"
							v-show="disabled"
						></span>
						{{ !disabled ? "Entrar" : "" }}
					</button>
				</div>
			</form>
		</ValidationObserver>
	</div>
</template>

<script>
	import load from "@/components/utils/_Load";
	export default {
		components: {
			load
		},
		data() {
			return {
				disabled: false,
				unidades: [],
				unidadeSelect: "",
				loader: true
			};
		},
		mounted() {
			this.$store
				.dispatch("getTenants")
				.then(resolve => {
					this.unidades = resolve;
					this.loader = false;
				})
				.catch(reject => {});
		},
		methods: {
			logCpf() {
				this.$refs.form.validate().then(success => {
					if (success) {
						this.$store.commit("set_tenant", this.unidadeSelect);
						this.$nextTick(() => {
							this.$router.push("/login");
						});
					}
				});
			}
		},
		watch: {
			unidadeSelect: function() {}
		}
	};
</script>

<style scoped>
</style>

